// librerías
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { BiMenuAltRight } from "react-icons/bi";
import { IoLanguage } from "react-icons/io5";

// contextos
import { headerContext } from "../../../context/headerContext";

// componentes
import Dropdown from "../dropdown/Dropdown";
import DropdownMovil from "../dropdownMovil/DropdownMovil";

// hooks
import { useContext } from "react";

// data
import DROPS from "../../../helpers/drops.json";
import PDFLink from "../PDFLink/PDFLink";

// styles
import "./header.css";
import { langContext } from "../../../context/langContext";

const Header = () => {
  const { setLang, changeLang } = useContext(langContext);

  const { closeMenu, setCloseMenu } = useContext(headerContext);

  return (
    <header className="section__header">
      <div className="content__header">
        <Link to="/" className="">
          <img
            className="logo__img"
            src="https://i.ibb.co/VYxn6CC/babilon-morado-simple-new.png"
            alt="logo babilon guitars"
          />
        </Link>
        {/* Movil button */}
        <div
          className="header__bntMovil"
          onClick={() => setCloseMenu(!closeMenu)}
        >
          {closeMenu ? <MdClose /> : <BiMenuAltRight />}
        </div>

        {/* Movil menu */}
        <div className={`header__nav-movile ${closeMenu && "show"}`}>
          <nav className="content__nav-movile">
            {DROPS.map((drop, i) => (
              <DropdownMovil info={drop} key={i} />
            ))}
          </nav>
          {/* Control del la descarga del manual */}
          {localStorage.getItem("lang") === "es-MX" ? (
            <PDFLink document={"./manual-babilon-2024.pdf"} leabel={"Manual"} />
          ) : (
            <PDFLink
              document={"./babilon-owner's-manual-2024.pdf"}
              leabel={"Manual"}
            />
          )}
          <span
            onClick={() => setLang(changeLang)}
            className="destok_navs-lang"
          >
            <IoLanguage />
            {changeLang === "es-MX" ? "Es" : "En"}
          </span>
        </div>

        {/* Desktop menu */}
        <nav className="header__nav">
          <div className="header__nav__logodes">
            <Link to="/">
              <img
                className="logo__img-des"
                src="https://i.ibb.co/VYxn6CC/babilon-morado-simple-new.png"
                alt="logo babilon guitars"
              />
            </Link>
          </div>
          <div className="destok_navs">
            {DROPS.map((drop, i) => (
              <Dropdown info={drop} key={i} />
            ))}
            {/* Control del la descarga del manual */}
            {localStorage.getItem("lang") === "es-MX" ? (
              <PDFLink
                document={"./manual-babilon-2024.pdf"}
                leabel={"Manual"}
              />
            ) : (
              <PDFLink
                document={"./babilon-owner's-manual-2024.pdf"}
                leabel={"Manual"}
              />
            )}
            {/* botón para cambiar el idioma */}
            <span
              onClick={() => setLang(changeLang)}
              className="destok_navs-lang"
            >
              <IoLanguage />
              {changeLang === "es-MX" ? "Es" : "En"}
            </span>
          </div>
        </nav>
        <div className="header__opacity-efect-bottom"></div>
      </div>
    </header>
  );
};

export default Header;
