// import { FaExternalLinkAlt } from "react-icons/fa";

// estilos
import "./PDFLink.css";

const PDFLink = ({ document, leabel }) => {
  return (
    <a href={document} target="_blank" rel="noreferrer" className="destok_manual">
      <span>{leabel}</span>
      {/* <FaExternalLinkAlt /> */}
    </a>
  );
};

export default PDFLink;
